export default defineNuxtRouteMiddleware(async (to, from) => {
  console.log('auth middleware...')

  const { user, isLoading } = useAuthStore()
  const nuxtApp = useNuxtApp()
  const locale = nuxtApp.$i18n.locale.value

  const startTime = Date.now()
  const maxWaitTime = 10000 // 10 seconds

  // Wait until the user state is definitely known or until the max wait time is exceeded
  while (isLoading && Date.now() - startTime < maxWaitTime) {
    await nextTick() // Wait for the next tick
  }

  if (isLoading || !user) {
    console.error('No user or loading user data timed out.. sorry bro')

    if (locale === 'de') {
      navigateTo('/login')
    }
    return navigateTo(`/${nuxtApp.$i18n.locale.value}/login`)
  }
})
